
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { getTagDetailServerSideProps } from '@web/pages/tag/[tag]';
import { Route } from '@web/routing';
import { TagDetail } from '@web/templates/TagDetail';
const getServerSideProps = getTagDetailServerSideProps(Route.Dossier);
export const Home = TagDetail;
export default Home;

    async function __Next_Translate__getServerSideProps__191eb1171d8__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/dossiers/[tag]',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__191eb1171d8__ as getServerSideProps }
  